@import '../../variables.scss';
@import '../../breakpoints.scss';

.ticketLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $white;
  font-family: 'Ropa Sans';
  font-size: 20px;
  padding: 4px;
  transition: 0.5s background-color;
  height: 60px;
  background-color: $darkRed;

  &:hover {
    cursor: pointer;
    background-color: rgba($blue, 0.8);
  }

  @include breakpoint(s) {
    background-color: rgba($blue, 0.6);
    height: 40px;
  }
}

.ticketWrapper {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;

  @include breakpoint(s) {
    bottom: 10px;
    left: 10px;
    width: 240px;
    margin-left: -400px;
    transition: 0.5s margin, 0.5s background-color;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  }
}

.linkText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  color: $white;
}
