@import '../../breakpoints.scss';

.artists {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  justify-content: space-around;
  margin-top: 0px;
  padding: 0 10px;
  gap: 40px;

  @include breakpoint(s) {
    margin-top: 40px;
    padding: 0;
    gap: 0
  }
}
