@import '../../variables.scss';

.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);

  &:hover {
    cursor: pointer;
  }
}

.closeButton {
  width: auto;
  border: 0;
  height: 40px;
  outline: none;
  background-color: transparent;
  transition: color, 0.5s;

  &:hover {
    cursor: pointer;

    .closeButtonText {
      color: $red;
    }
  }
}

.closeButtonText {
  color: $white;
  font-family: 'Ropa Sans', sans-serif;
  font-size: 24px;
  text-shadow: 0 0 5px #000;
  position: relative;
}
