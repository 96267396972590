@import '../../variables.scss';
@import '../../breakpoints.scss';

.container {
  position: relative;
  transition: height 0.5s, width 0.5s;
  max-width: 540px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #fff;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column; 
  
  @include breakpoint(s) {
    padding: 10px;
    background-color: $almostBlack;
    margin: 20px;
    border-bottom: 0;
  }
}

.imageWrapper {
  width: 100%;
  text-align: center;
}

.image {
  width: 100%;
  margin-bottom: 10px;
}

.name {
  font-family: Sarpanch, sans-serif;
  text-align: left;
  font-size: 22px;

  @include breakpoint(s) {
    font-size: 30px;
    padding: $artistContainerPadding;
  }
}

.countryCode {
  font-size: 20px;
  margin: 0.2em 0 0 0.2em;
}

.description {
  font-size: 22px;
  color: #fff;
  text-align: left;
  flex-grow: 1;

  @include breakpoint(s) {
    padding: 10px;
    max-height: 400px;
  }
}

.shortDescription {
  margin-bottom: 10px;
}

.readMoreButton {
  font-family: 'Open Sans', sans-serif;
  font-weight: 900;
  border: 0;
  padding: 0;
  height: 40px;
  outline: none;
  background-color: transparent;
  transition: color, 0.5s;

  &:hover {
    cursor: pointer;
    .readMoreText {
      color: $red;
    }
  }
}

.readMoreText {
  transition: 0.5s color;
  color: $gold;
  font-size: 16px;
  position: relative;
}

.artistHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.spotifyLogo {
  opacity: 0.9;
  width: 24px;
  height: 24px;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  @include breakpoint(s) {
    width: 40px;
    height: 40px;
  }
}

.soundCloudLogo {
  opacity: 0.9;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  width: 38px;
  height: 38px;
  @include breakpoint(s) {
    width: 50px;
    height: 50px;
  }
}
.subtitle {
  font-size: 22px;
  font-weight: 100;
}

.footerLinks {
  display: inline;

  @include breakpoint(s) {
    padding: 10px;
    max-height: 400px;
  }
}

.linkHeader {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 14px; 
  margin-right: 8px;
}

.artistLink {
  font-size: 12px; 
  text-decoration: none;
}

.spotifyLink {
  font-size: 12px; 
  text-decoration: none;
  &:hover {
    cursor: pointer;

    color: $blue
  }
}

