$white: #ffffff;
$black: #000000;
$almostBlack: #111111;
$red: #ee3333;
$darkRed: crimson;
$blue: dodgerblue;
$darkSlate: darkslategray;
$gold: goldenrod;
$linkColor: #22aaee;
$artistContainerPadding: 10px;
$soldOut: $red;
