.wrapper {
  opacity: 0;
  transition: 0.5s opacity;
}

.fadeIn {
  opacity: 1;
}

.loader {
  position: absolute;
  font-size: 16px;
  opacity: 0.5;
}
