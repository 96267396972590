$breakpoints: (
  's': (
    min-width: 767px,
  ),
  'm': (
    min-width: 992px,
  ),
  'l': (
    min-width: 1200px,
  ),
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
