@import '../../variables.scss';
@import '../../breakpoints.scss';

.countdownWrapper {
  display: none;

  @include breakpoint(s) {
    display: flex;
    position: absolute;
    width: 100%;
    max-width: 980px;
    text-align: right;
    top: 0;
    transform: translateX('20px');
  }
}

.countdown {
  padding: 4px 8px;
  margin: 10px 20px;
  background-color: rgba($black, 0.8);
  font-family: 'Sarpanch', sans-serif;
  font-size: 30px;
  line-height: 30px;
  color: $white;
  margin-left: auto;
}

.box {
  display: inline-block;
  width: 50px;
  text-align: center;
}
