@import '../../../variables.scss';
@import '../../../breakpoints.scss';

.widget {
  height: 80px;
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.5s margin;
  box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.5);
  width: 100%;
  margin-right: 0px;

  @include breakpoint(s) {
    margin-right: -320px;
    width: 300px;
  }
}

.slideIn {
  margin-right: 0;
}

.closeButton {
  background: none;
  border: 0;
  color: $white;
  font-size: 16px;
  position: absolute;
  text-decoration: underline;
  right: 6px;
  margin-top: -23px;
  transition: 0.5s color;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 4px 0 0 2px;

  &:hover {
    cursor: pointer;
    color: $red;
  }
}
