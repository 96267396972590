@import '../../breakpoints.scss';

.ticketIcon {
  max-width: 40px;
  max-height: 40px;
  width: 40px;
  height: 40px;
  margin-bottom: -4px;
  margin-right: 10px;
  margin-left: -26px;

  &:hover {
    cursor: pointer;
  }

  @include breakpoint(s) {
    margin-left: 0;
  }
}
