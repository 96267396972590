@import '../../variables.scss';
@import '../../breakpoints.scss';

.container {
  position: relative;
  padding: 10px;
  transition: height 0.5s, width 0.5s;
  text-align: left;
  background-color: $almostBlack;
  border: 1px solid #555;
  box-shadow: 0 0 40px 10px #000;
  overflow: auto;
  margin: 0;
  max-height: 90%;

  &:hover {
    cursor: auto;
  }

  @include breakpoint(s) {
    margin: 20px auto;
    max-width: 980px;
  }
}

.imageWrapper {
  width: 100%;
  text-align: center;
}

.image {
  max-width: 240px;
  float: left;
  margin-right: 20px;
  display: none;

  @include breakpoint(s) {
    margin-bottom: 20px;
    display: block;
  }
}

.name {
  font-family: Sarpanch, sans-serif;
  text-align: left;
  display: flex;
  align-items: flex-start;
  font-size: 32px;

  @include breakpoint(s) {
    padding: $artistContainerPadding 0;
  }
}

.countryCode {
  font-size: 20px;
  margin: 0.2em 0 0 0.2em;
}

.description {
  max-height: none;
}

.shortDescription {
  margin-bottom: 10px;
}

.readMoreButton {
  font-family: 'Open Sans', sans-serif;
  font-weight: 900;
  border: 0;
  padding: 0;
  height: 40px;
  outline: none;
  background-color: transparent;
  transition: color, 0.5s;
  &:hover {
    cursor: pointer;
    .readMoreText {
      color: $red;
    }
  }
}

.readMoreText {
  transition: 0.5s color;
  color: $gold;
  font-size: 16px;
  position: relative;
}

.artistHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subtitle {
  font-size: 22px;
  font-weight: 100;
}

.break {
  clear: both;
}

