@import '../../breakpoints.scss';
@import '../../variables.scss';

.list {
  list-style-type: none;
  padding: 0;
  margin: 60px auto 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include breakpoint(s) {
    width: 500px;
  }
}

.heading {
  font-size: 22px;
  padding: 0;
  margin: auto;

  @include breakpoint(s) {
    margin: 0;
  }
}

.item {
  font-family: 'Sarpanch', sans-serif;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  padding: 8px 10px;
  width: 100%;
  font-size: 16px;

  @include breakpoint(s) {
    font-size: 22px;
  }

  &:nth-of-type(even) {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.time {
  display: inline-block;
  text-align: left;
  margin-right: 20px;
  color: $gold;
}

.event {
  display: inline-block;
  text-align: left;
  margin-right: 20px;
}

.stage {
  display: inline-block;
  text-align: left;
  font-size: 14px;

}
