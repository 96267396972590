@import '../../variables.scss';
@import '../../breakpoints.scss';

.footer {
  font-size: 22px;
  position: absolute;
  left: 0;
  width: 100%;
  margin-bottom: 40px;

  @include breakpoint(s) {
    margin-bottom: 0;
  }
}

.linkText {
  color: $white;
  text-decoration: none;
  transition: 0.3s color;
  font-size: 16px;

  @include breakpoint(s) {
    font-size: 20px;
  }
}

.facebookLink {
  text-align: center;

  &:hover {
    .linkText {
      color: $blue;
    }
  }
}

.copyrightContainer {
  border-top: 1px dashed rgba($white, 0.6);
  margin: 20px 0;
  padding: 20px 0;
  font-size: 14px;

  @include breakpoint(s) {
    font-size: 20px;
  }
}
