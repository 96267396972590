@import './variables.scss';
@import './breakpoints.scss';

.appContainer {
  width: 100%;
  text-align: center;
  color: $white;
  padding: 0 10px;

  @include breakpoint(s) {
    padding: 0 20px;
  }

  @include breakpoint(m) {
    padding: 0;
  }
}

.welcomeText {
  font-size: 16px;

  @include breakpoint(s) {
    font-size: 22px;
  }
}

.thankYouText {
  font-family: Sarpanch, sans-serif;
  color: $gold;
  margin-top: 40px;
  font-size: 18px;

  @include breakpoint(s) {
    font-size: 26px;
  }
}

.ticketText {
  font-family: Sarpanch, sans-serif;

  font-size: 24px;
  display: none;

  @include breakpoint(s) {
    display: block;
  }
}

.ticketsNotReleasedText {
  font-size: 16px;
  font-weight: 800;

  @include breakpoint(s) {
    font-size: 22px;
  }
}

.ticketTextMobile {
  font-family: Sarpanch, sans-serif;

  font-size: 28px;

  @include breakpoint(s) {
    display: none;
  }
}

.slideIn {
  margin-left: 0;
}

.infoWrapper {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include breakpoint(s) {
    flex-direction: row;
  }
}
.infoWrapperChildren {
  width: 100%;

  @include breakpoint(s) {
    width: 540px;
    margin: 20px;
    padding: 20px;
  }
}

.soldOutHeading {
  color: $soldOut;
  margin: 6px 0;
  font-size: 32px;
  line-height: 36px;

  @include breakpoint(s) {
    font-size: 40px;
    margin: 16px 0 8px;
  }
}

.soldOutText {
  font-size: 14px;
  margin: 0 0 12px;

  @include breakpoint(s) {
    margin-top: 8px;
    font-size: 22px;
  }
}
