@import '../../variables.scss';
@import '../../breakpoints.scss';

.artist {
  background-color: $black;
  color: rgba($white, 0.6);
  border: 1px solid $darkSlate;
  border-radius: 3px;
  font-size: 14px;
  flex-grow: 1;
  text-align: center;
  transition: 0.5s color, 0.5s background-color;
  margin: 2px;
  padding: 2px 4px;

  &:hover {
    cursor: pointer;
    color: $white;
    background-color: $darkSlate;
  }

  @include breakpoint(s) {
    padding: 4px;
    margin: 4px;
    font-size: 18px;
  }
}

.activeArtist {
  color: $white;
  background-color: $darkSlate;
}

.artistHistory {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.artistHistoryHeading {
  font-size: 18px;
  text-align: left;

  @include breakpoint(s) {
    margin-top: -52px;
    font-size: 22px;
  }
}

.yearMenu {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex-wrap: wrap;
}

.year {
  background-color: $black;
  outline: none;
  color: $white;
  border: 1px solid rgba($darkRed, 0.8);
  border-radius: 3px;
  font-size: 14px;
  transition: 0.5s background-color;
  flex-grow: 1;
  margin: 2px;
  padding: 4px;

  &:hover {
    cursor: pointer;
    background-color: $darkRed;
  }

  @include breakpoint(s) {
    padding: 5px 10px;
  }
}

.selectedYear {
  background-color: $darkRed;
}

.mainWrapper {
  text-align: center;
  max-width: 1200px;
  margin: auto;
  display: flex;
}

.section {
  max-width: 1200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include breakpoint(m) {
    flex-direction: row;
  }
}

.yearsWrapper {
  max-width: 900px;

  @include breakpoint(m) {
    margin-right: 20px;
  }
}

.flyerWrapper {
  max-width: 320px;
  transition: width 0.5s;
  margin: 32px auto 0 auto;

  @include breakpoint(m) {
    margin: 0;
  }
}
