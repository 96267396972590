@import './variables.scss';
@import './breakpoints.scss';

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #fff;
}
.fixedBody {
  overflow: hidden;
}

h1 {
  font-family: Sarpanch, sans-serif;
  font-size: 24px;
  margin: 0;
  @include breakpoint(s) {
    font-size: 30px;
  }
}
h2 {
  font-family: Sarpanch, sans-serif;
  font-size: 26px;
}

p {
  font-family: 'Open Sans';
  font-size: 16px;
}

section {
  max-width: 800px;
  text-align: left;
  margin: 10px auto;
  padding: 0 10px;

  @include breakpoint(s) {
    margin: 40px auto;
    padding: 0;
  }
}

a {
  color: $white;
  text-decoration: underline;
  &:hover {
    color: $linkColor;
  }
}

li {
  font-size: 22px;
}
