@import '../../variables.scss';
@import '../../breakpoints.scss';

.headerDate {
  color: $gold;
  font-family: 'Sarpanch', sans-serif;
  font-size: 20px;
  line-height: 22px;
  margin-top: 20px;

  @include breakpoint(s) {
    font-size: 30px;
    line-height: 36px;
  }
}

.logoWrapper {
  max-width: 980px;
  width: auto;
  margin: auto;

  & img {
    width: 100%;
  }
}

.soldOutSplash {
  display: none;

  @include breakpoint(s) {
    display: block;
    position: absolute;
    transform: rotate(-14deg);
    font-size: 48px;
    top: -20px;
    color: $soldOut;
    font-weight: 800;
    text-shadow: 4px 4px 2px $black;
  }

  @include breakpoint(m) {
    font-size: 68px;
    top: -40px;
  }

  @include breakpoint(l) {
    margin-left: -48px;
  }
}
