@import '../../breakpoints.scss';

.lineup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 980px;
  text-transform: uppercase;
  font-family: Sarpanch, sans-serif;
  margin: 16px auto;

  @include breakpoint(s) {
    margin: 40px auto;
  }
}

.headliner {
  font-size: 22px;
  line-height: 28px;
  width: 100%;
  font-family: Sarpanch, sans-serif;

  @include breakpoint(s) {
    line-height: 58px;
    margin: 20px 0;
    font-size: 50px;
  }

  @include breakpoint(m) {
    line-height: 68px;
    font-size: 70px;
  }
}

.nonHeadliner {
  font-size: 20px;
  width: auto;
  display: flex;
  align-items: flex-start;
  font-family: Sarpanch, sans-serif;
  margin: 0 14px;

  @include breakpoint(s) {
    margin: 10px 20px;
    font-size: 40px;
  }
}

.djs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  flex-wrap: wrap;
}

.dj {
  font-size: 14px;
  width: auto;
  display: flex;
  align-items: flex-start;
  font-family: Sarpanch, sans-serif;
  text-transform: uppercase;
  margin: 0 14px;

  @include breakpoint(s) {
    margin: 10px 20px;
    font-size: 20px;
    width: auto;
  }
}

.headlinerFlag {
  width: 15px;
  height: 12px;
  margin-left: 6px;
  margin-right: -21px;
  margin-top: 5px;

  @include breakpoint(s) {
    width: 52px;
    height: 40px;
    margin-left: 26px;
  }
}

.nonHeadlinerFlag {
  margin-top: 8px;
  margin-right: -16px;

  @include breakpoint(s) {
    margin-top: 16px;
  }
}

.djFlag {
  width: 14px;
  margin-top: 6px;
  margin-right: -16px;

  @include breakpoint(s) {
    width: 18px;
    margin-top: 6px;
    margin-right: 6px;
    margin-left: 6px;
  }
}

.tbaText, .artistsWillBeReleasedText {
  line-height: 16px;
  font-size: 16px;
  font-family: Sarpanch, sans-serif;

  @include breakpoint(s) {
    margin-top: -20px;
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 24px;
  }
}
