@import '../../breakpoints.scss';

.flag {
  width: 14px;
  height: 10px;
  margin-left: 6px;
  margin-top: 8px;  
  
  @include breakpoint(s) {
    width: 27px;
    height: 20px;
    margin-left: 14px;
    margin-top: 12px;
  }
}
